import axios from "axios";
import React, { useState, useEffect } from "react";
import { url as apiUrl } from "../../api";

interface Stats {
  totalUsers: number;
  totalFriendsFamilyUsers: number;
  totalAOTD: number;
  totalMessages: number;
  totalTokensUsed: number;
  usersWithTheMostTokens: Array<any>;
  aotdAuthors: Array<any>;
}

interface Leaderboard {
  totalAOTD: number;
  aotdAuthors: Array<any>;
}

export const useLoadStats = () => {
  const url = apiUrl as string; // Add a type assertion here
  const [fetched, setFetched] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [stats, setStats] = useState<Stats>();
	const [leaderboard, setLeaderboard] = useState<Leaderboard>()

  const getAllStats = async () => {
    if (!fetched) {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const headers: HeadersInit = {
          ...(token ? { "x-auth-token": token } : {}),
        };
        const response = await fetch(`${url}/api/site-stats/full`, { headers });
        const statsRes = (await response.json()) as Stats;

        if (response.status === 200) {
          setStats(statsRes);
          setFetched(true);
          setLoading(false);
        } else {
          console.error("Error fetching full stats:", response.status);
        }
      } catch (error) {
        console.error("Error fetching full stats:", error);
      }
    }
  };

	const getLeaderboard = async () => {
		try {
			const response = await fetch(`${url}/api/site-stats/leaderboard?year=${new Date().getFullYear()}`);
			const lbRes = (await response.json()) as Leaderboard;

			if (response.status === 200) {
				setLeaderboard(lbRes);
			} else {
				console.error("Error fetching leaderboard:", response.status);
			}
		} catch (error) {
			console.error("Error fetching leaderboard:", error);
		}
	}

  useEffect(() => {
    getAllStats();
		getLeaderboard();
  }, [url]);

  return { stats, leaderboard };
};
